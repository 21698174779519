/* ++++++++++++++++ Table +++++++++++++++++++++ */
table.freeze-col tr th:first-child,
table.freeze-col td:first-child {
  position: sticky;
  width: 100px;
  left: 0;
  z-index: 10;
  background: #fff;
}

table.freeze-col tr th:first-child {
  z-index: 11;
}
table.freeze-col tr th {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;
}

.inline-icon {
  color: $primary;
  padding-left: 5px;
  cursor: pointer;
}

/* ++++++++++++++++ BR Audit +++++++++++++++++++++ */
.audit-seg {
  background-color: #faebd7;
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}

.audit-seg button .collapsed {
  background-color: #fff;
}

.audit-seg button {
  background-color: #fff !important;
  padding: 0.5rem 1.25rem;
}

.br-audit .accordion-button::after {
  margin-left: 7px !important;
}

.unified-toolbar {
  width: 50px;
  font-size: 12px;
}

.unified-stat {
  width: 90px;
  display: inline-table;
  text-align: left;
}

.unified-vr {
  margin: 0 4px !important;
  display: inline-block;
}

.recon-err {
  color: $danger;
  font-size: 11px;
}

.recon-success {
  color: $success;
  font-size: 11px;
}

.sidebar-header span {
  font-size: 35px;
}

.upload-status {
  width: 15%;
}