.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.left-hand-menu {
  background-color: $menu-bg-hover;
}

.left_hand_menu-item {
  padding-left: 10px;
}

.left_hand_menu-item  .btn-link {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  width: 100%; 
  padding: 10px 15px;
  text-decoration: none; 
}

.left-hand-menu .dropdown {
  width: 100%;
}

.left-hand-menu .dropdown-toggle {
  width: 10px;
}